import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/package-details/:id',
    name: 'packageDetails',
    component: () => import(/* webpackChunkName: "packageDetails" */ '../views/packages/packageDetails.vue'),
    props: true
  },
  {
    path: '/package/:id/booking',
    name: 'packageBook',
    component: () => import(/* webpackChunkName: "bookDetails" */ '../views/packages/bookPackage.vue'),
    props: true
  },
  {
    path: '/package/confirm',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "bookDetails" */ '../views/packages/confirm.vue'),
    props: true
  },
  {
    path: '/flights/flights-results',
    name: 'flightResults',
    component: () => import(/* webpackChunkName: "flightResults" */ '../views/flights/flightResults.vue'),
    props: true
  },
  {
    path: '/hotels-mecca/stop/:stop',
    name: 'meccaHotels',
    component: () => import(/* webpackChunkName: "hotel-mecca-results" */ '../views/hotels/meccaHotels.vue'),
    props: true
  },
  {
    path: '/hotels-medina/stop/:stop',
    name: 'medinaHotels',
    component: () => import(/* webpackChunkName: "hotel-mecca-results" */ '../views/hotels/medinaHotels.vue'),
    props: true
  },
  {
    path: '/package/package-hotels',
    name: 'packageHotels',
    component: () => import(/* webpackChunkName: "hotels" */ '../views/packages/hotels.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
