import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import i18n from './i18n'
import { bookingEngine } from './APILinks'
import ReadMore from 'vue-read-more'

Vue.component('date-picker', DatePicker)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(require('vue-moment'))

Vue.use(ReadMore)

Vue.axios.get(bookingEngine + 'getCurrencies').then(response => {
  if (response.data.status) {
    store.dispatch('setCurrencies', response.data.data)
  }
  if (process.env.VUE_APP_DEFAULT_CURRENCY) {
    store.dispatch('setCurrency', response.data.data.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY))
  }
}).catch(err => {
  console.log(err)
})

Vue.filter('changeCurrency', function (price) {
  // const defaultRate = store.state.currencies.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY)
  // const finalPrice = (price / defaultRate.rate) * store.state.currency.rate
  const finalPrice = price * store.state.currency.rate

  return store.state.currency.code + ' ' + finalPrice.toFixed(2)
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
