<template>
    <div>
        <div class="d-flex align-center mb-2">
            <v-btn
            small
            elevation="0"
            tile
            color="headers white--text"
            class="font-weight-bold"
            :class="small ? 'pa-2 body-1' : 'pa-4 text-h6'"
            @click="$emit('increase')"
            >
                <v-icon :small="small">mdi-plus</v-icon>
            </v-btn>

                <div style="min-width: 15px" class="headers white--text lighten-2" :class="small ? 'pa-1 body-2' : 'px-2 text-h6'">{{count}}</div>

            <v-btn
            small
            elevation="0"
            tile
            color="headers white--text"
            class="font-weight-bold"
            :class="small ? 'pa-2 body-1' : 'pa-4 text-h6'"
            @click="$emit('decrease')"
            >
                <v-icon :small="small">mdi-minus</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
  props: ['count', 'small']
}
</script>
