<template>
    <div>
        <!-- rooms counter -->
        <div class="d-flex justify-space-between flex-wrap">
            <div class="text-h6 headers--text font-weight-medium mb-2">{{$t('hotel.hotels.rooms')}}</div>
            <counter :count="roomsAndoccupancy.length" @increase="$store.dispatch('increaseRoom')"  @decrease="$store.dispatch('decreaseRoom')" />
        </div>

        <v-divider></v-divider>

        <!-- travellers counters -->
        <div v-for="(room, i) in roomsAndoccupancy" :key="i">
            <v-row no-gutters justify="space-between" align="center">
                <v-col cols="12" sm="4">
                    <div class="text-h6 headers--text font-weight-medium mb-2">{{$t('hotel.hotels.room') + ' ' + (i + 1)}}</div>
                </v-col>

                <v-col cols="12" sm="4" class="text-center">
                    <span class="text-h6 headers--text font-weight-medium mb-2">{{$t('flightSearch.occupancyTerms.adults')}}</span>
                    <counter :count="room.adults" @increase="$store.dispatch('increaseAdultsNumbers', i)" @decrease="$store.dispatch('decreaseAdultsNumbers', i)" />
                </v-col>

                <v-col cols="12" sm="4" class="text-center">
                    <span class="text-h6 headers--text font-weight-medium mb-2">{{$t('flightSearch.occupancyTerms.children')}}</span>
                    <counter :count="room.children.length" @increase="$store.dispatch('increaseChildrenNumbers', i)" @decrease="$store.dispatch('decreaseChildrenNumbers', i)" />
                </v-col>
            </v-row>

            <!-- children ages -->
            <div v-if="room.children.length">
                <div class="body-1 headers--text font-weight-medium mb-2">{{$t('hotel.hotels.childrenAges')}}</div>

                <v-row no-gutters>
                    <div v-for="(child, j) in room.children" :key="j" class="pa-1">
                        <counter :small="true" :count="child.age" @increase="$store.dispatch('increaseChildAge', {roomIndex: i, childIndex: j})" @decrease="$store.dispatch('decreaseChildAge', {roomIndex: i, childIndex: j})" />
                    </div>
                </v-row>
            </div>

            <v-divider class="my-2"></v-divider>
        </div>
    </div>
</template>

<script>
import counter from './counter'
import { mapState } from 'vuex'

export default {
  components: {
    counter
  },
  computed: {
    ...mapState(['roomsAndoccupancy', 'occupancyNote'])
  }
}
</script>
