<template>
    <div>
      <div dir="ltr" class="primary px-5 pb-2" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right'">
        <a class="white--text body-2" href="mailto:onlinesupport@adamtravel.com"><v-icon small color="white">mdi-email</v-icon> onlinesupport@adamtravel.com</a>
        <span class="headline white--text mx-1">|</span>
        <a class="white--text body-2" href="tel:18773542326"><v-icon small color="white">mdi-phone</v-icon> 1-877-354-2326</a>
      </div>
      <v-app-bar
      light
      color="white"
      elevation="3"
      :hide-on-scroll="false"
      >
        <v-container :fluid="$vuetify.breakpoint.mdAndDown">
          <v-row align="center" justify="space-between">
            <v-col cols="5" class="d-flex justify-space-between">
              <v-img
              @click="$route.path !== '/' ? $router.push({ name: 'Home' }) : ''"
              alt="Adam Logo"
              class="shrink mr-2"
              contain
              src="../assets/logo-adam.png"
              transition="scale-transition"
              width="150"
              style="cursor: pointer;"
              />
              <div class="d-none d-md-flex">
                <v-btn color="primary" @click="goTo('offices')" height="53" text><span class="body-2 font-weight-bold">{{$t('navBar.offices')}}</span></v-btn>
                <v-btn color="primary" @click="goTo('about-adam')" height="53" text><span class="body-2 font-weight-bold">{{ $t('navBar.about') }}</span></v-btn>
                <v-btn color="primary" @click="goTo('contact-adam')" height="53" text><span class="body-2 font-weight-bold">{{$t('navBar.contact')}}</span></v-btn>
              </div>
            </v-col>

            <v-btn icon @click="drawer = !drawer" class="d-flex d-md-none">
              <v-icon large color="primary">mdi-menu</v-icon>
            </v-btn>
            <v-col cols="7" :class="$i18n.locale === 'ar' ? 'text-left' : 'text-right'" class="d-none d-md-block mb-1">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  class="mb-1"
                  color="primary"
                  dark
                  v-bind="attrs"
                  text
                  v-on="on"
                  >
                    <v-icon left>mdi-cash</v-icon> {{currency.code}}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                  v-for="(item, i) in currencies"
                  :key="i"
                  @click="$store.dispatch('setCurrency', item)"
                  >
                    <v-list-item-title>{{ item.code }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <span class="headline mx-5">|</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  class="mb-1"
                  color="primary"
                  dark
                  v-bind="attrs"
                  text
                  v-on="on"
                  >
                    <v-icon>mdi-translate</v-icon>
                    {{$i18n.locale === 'en' ? 'EN' : 'عربي'}}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                  v-for="(item, i) in locales"
                  :key="i"
                  @click="switchLocale(item)"
                  >
                    <v-list-item-title>{{ item === 'en' ? 'EN' : 'عربي' }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <v-navigation-drawer
      v-model="drawer"
      absolute
      color="white"
      dark
      temporary
      >
        <v-list nav dense>
          <v-list-item>
            <v-img src="../assets/logo-adam.png" alt="adam logo" max-width="120px" class="mx-auto"></v-img>
          </v-list-item>

          <v-list-item-group
          color="primary"
          >
          <v-list-item @click="goTo('offices')">
              <v-list-item-content class="text-center">
                  <v-list-item-title class="text--primary">{{$t('navBar.offices')}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goTo('about-adam')">
              <v-list-item-content class="text-center">
                  <v-list-item-title class="text--primary">{{$t('navBar.about')}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goTo('contact-adam')">
              <v-list-item-content class="text-center">
                  <v-list-item-title class="text--primary">{{$t('navBar.contact')}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  class="mb-1"
                  color="primary"
                  dark
                  v-bind="attrs"
                  text
                  v-on="on"
                  >
                    <v-icon left>mdi-cash</v-icon> {{currency.code}}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                  v-for="(item, i) in currencies"
                  :key="i"
                  @click="$store.dispatch('setCurrency', item)"
                  >
                    <v-list-item-title>{{ item.code }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  class="mb-1"
                  color="primary"
                  dark
                  v-bind="attrs"
                  text
                  v-on="on"
                  >
                    <v-icon>mdi-translate</v-icon>
                    {{$i18n.locale}}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                  v-for="(item, i) in locales"
                  :key="i"
                  @click="switchLocale(item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-content>
          </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      drawer: false,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  },
  computed: {
    ...mapState(['currency', 'currencies'])
  },
  methods: {
    goTo (link) {
      let baseUrl
      switch (link) {
        case 'about-adam':
          baseUrl = 'https://adamtravel.com/our-history/'
          window.open(baseUrl, '_blank')
          break
        case 'contact-adam':
          baseUrl = 'https://adamtravel.com/contact-layout-2/'
          window.open(baseUrl, '_blank')
          break
        case 'offices':
          baseUrl = 'https://adamtravel.com/offices/'
          window.open(baseUrl, '_blank')
          break
        default:
          this.$router.push({ name: link })
          break
      }
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        this.$vuetify.rtl = this.$i18n.locale === 'ar'
      }
    }
  }
}
</script>

<style>
a {
  text-decoration: none;
}
</style>
