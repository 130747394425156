<template>
    <div>
      <!-- flight -->
      <flights-search @error="displayError" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import flightsSearch from '@/components/flights/flightsSearch'

export default {
  components: {
    flightsSearch
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    ...mapState(['adults', 'children', 'oldChildren', 'infants'])
  },
  methods: {
    changePassengersNumbers (passengerType, method) {
      this.$store.dispatch('changePassengersNumbers', { type: passengerType, action: method })
      if (this.infants > this.adults) this.$store.dispatch('changePassengersNumbers', { type: 'infants', action: 'dec' })
      if (this.oldChildren + this.adults > 4) this.$store.dispatch('changePassengersNumbers', { type: 'oldChildren', action: 'dec' })
    },
    displayError (msg) {
      this.$emit('error', msg)
    }
  }
}
</script>
