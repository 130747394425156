<template>
    <v-footer
    padless
    elevation="3"
    class="mt-15"
    >
    <v-card
    tile
    width="100%"
    color="primary"
    class="adam-footer"
    >
        <v-card-text>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" class="pr-15">
                <v-img src="../assets/logo-adam-white.png" max-width="150"></v-img>
                <p class="subtitle-1 white--text">{{$t('footer.slogan')}}</p>
                <p class="body-2 mt-5 white--text">
                  {{$t('footer.desc')}}
                </p>
                <p class="body-2 mt-5 white--text">
                  {{$t('footer.service')}}
                </p>
                <div class="social-adam-cont">
                  <span class="body-1 white--text font-weight-bold ">{{$t('footer.follow')}}</span>
                  <a class="mr-5" href="https://www.facebook.com/AdamTravelServices/" target="_blank"><v-icon color="white">mdi-facebook</v-icon></a>
                  <a class="mr-5" href="https://twitter.com/adamtravelusa" target="_blank"><v-icon color="white">mdi-twitter</v-icon></a>
                  <a class="mr-5" href="https://www.instagram.com/adamtravelusa/" target="_blank"><v-icon color="white">mdi-instagram</v-icon></a>
                </div>
              </v-col>

              <v-col cols="12" sm="2">
                <span class="white--text font-weight-bold" style="font-size: 20px;">{{$t('footer.access')}}</span>
                <hr class="divider">
                <ul class="links-list">
                  <li>
                    <span class="body-1 white--text" @click="goTo('offices')" height="53" text>{{$t('navBar.offices')}}</span>
                  </li>
                  <li>
                    <span class="body-1 white--text" @click="goTo('about-adam')" height="53" text>{{$t('navBar.about')}}</span>
                  </li>
                  <li>
                    <span class="body-1 white--text" @click="goTo('contact-adam')" height="53" text>{{$t('navBar.contact')}}</span>
                  </li>
                </ul>
              </v-col>

              <v-col cols="12" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">{{$t('navBar.contact')}}</span>
                <hr class="divider">
                <ul class="links-list">
                  <li>
                    <a class="white--text" href="mailto:onlinesupport@adamtravel.com"><v-icon color="white" left>mdi-email</v-icon> onlinesupport@adamtravel.com</a>
                  </li>
                  <li>
                    <a class="white--text" href="tel:18773542326"><v-icon color="white" left>mdi-phone</v-icon> 1-877-354-2326</a>
                  </li>
                </ul>
              </v-col>
            </v-row>
            <p class="caption grey--text text-center mx-auto mb-0 mt-5">
              &copy; {{ new Date().getFullYear() + ' ' + $t('footer.copyRight') }}
            </p>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
export default {
  methods: {
    goTo (link) {
      let baseUrl
      switch (link) {
        case 'about-adam':
          baseUrl = 'https://adamtravel.com/our-history/'
          window.open(baseUrl, '_blank')
          break
        case 'contact-adam':
          baseUrl = 'https://adamtravel.com/contact-layout-2/'
          window.open(baseUrl, '_blank')
          break
        case 'offices':
          baseUrl = 'https://adamtravel.com/offices/'
          window.open(baseUrl, '_blank')
          break
        default:
          this.$router.push({ name: link })
          break
      }
    }
  }
}
</script>

<style>
.divider {
  background-color:#fff;
  width: 50px;
  height: 3px;
  margin-top: 5px;
  border: none;
}
.links-list {
  list-style: none;
  margin-top: 20px;
  padding: 0px !important;
}
.links-list li {
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
