<template>
    <div>
      <h2 class="body-1 lightBlue--text mb-5">{{$t('flightSearch.departure')}}</h2>
      <v-row>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
          <autocomplete tripType="from" @error="displayError" />
        </v-col>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
          <!-- destination airport -->
          <v-select
          prepend-inner-icon="mdi-airplane-landing"
          :items="destinationAirports"
          v-model="destination"
          item-text="Name"
          item-value="Code"
          color="headers"
          return-object
          outlined
          rounded
          flat
          item-color="blue"
          :label="$t('flightSearch.to')"
          >
            <template v-slot:item="{ item }">
              <span class="blueDark--text">
                {{item.Name + ', ' + item.Code}}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="blueDark--text">
                {{item.Name+ ', ' + item.Code}}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <h2 class="body-1 lightBlue--text my-5">{{$t('flightSearch.return')}}</h2>
      <v-row>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
        <!-- return airport -->
          <v-select
          prepend-inner-icon="mdi-airplane-takeoff"
          :items="returnAirports"
          v-model="returnSaudiAirport"
          item-text="Name"
          item-value="Code"
          color="headers"
          return-object
          outlined
          rounded
          flat
          item-color="blue"
          :label="$t('flightSearch.from')"
          >
            <template v-slot:item="{ item }">
              <span class="blueDark--text">
                {{item.Name + ', ' + item.Code}}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="blueDark--text">
                {{item.Name+ ', ' + item.Code}}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
          <autocomplete tripType="to" @error="displayError" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" :md="$route.path === '/' ? 6 : 12" class="py-0">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.tripStartDate')}}</h2>
          <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tempStartDateText"
                readonly
                v-bind="attrs"
                rounded
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
            v-model="tempDates[0]"
            :min="minStartDate"
            :max="new Date(packageDetails.season_validation[0].end_season_ongoing).toISOString().substring(0, 10)"
            show-current="false"
            color="headersText darken-3"
            no-title
            show-adjacent-months
            @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" :md="$route.path === '/' ? 6 : 12" class="py-0">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.tripReturnDate')}}</h2>
          <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tempEndDateText"
                readonly
                v-bind="attrs"
                v-on="on"
                rounded
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
            v-model="tempDates[1]"
            :min="packageReturnMinDate"
            :max="packageReturnMaxDate"
            show-current="false"
            color="headersText darken-3"
            no-title
            show-adjacent-months
            :readonly="!tempDates[0]"
            @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <!-- durations -->
      <v-row>
        <v-col cols="12" :md="$route.path === '/' ? 6 : 12">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.meccaDurations')}}</h2>
          <v-text-field v-model="meccaStayDurations" type="number" rounded outlined flat :hide-details="!!meccaStayDurations && meccaStayDurations >= 2" min="2" :max="maxStayDuration - medinaStayDurations" :rules="[v => !!v || 'Field is Required', v => v >= 2 || 'Stay must be 2 nghts or more']"></v-text-field>
        </v-col>
        <v-col cols="12" :md="$route.path === '/' ? 6 : 12">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.medinaDurations')}}</h2>
          <v-text-field v-model="medinaStayDurations" type="number" rounded outlined flat :hide-details="!!medinaStayDurations && medinaStayDurations >= 0" min="0" :max="maxStayDuration - meccaStayDurations" :rules="[v => !!v || 'Field is Required', v => v >= 0 || 'No negative digits allowed']"></v-text-field>
        </v-col>
      </v-row>

      <!-- flight class -->
      <v-row>
        <v-col cols="12" :md="$route.path === '/' ? 6 : 12">
          <h2 class="lightBlue--text body-1 my-3">{{$t('flightSearch.tripClass')}}</h2>
          <v-select
          :items="classes"
          v-model="classType"
          color="headers"
          return-object
          rounded
          outlined
          flat
          hide-details
          item-color="headers"
          @change="setClass"
          >
            <template v-slot:selection="{ item }">
              <span class="blueDark--text">
                {{translateClass(item.text)}}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" :md="$route.path === '/' ? 6 : 12">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.occupancy')}}</h2>
          <v-menu
          v-model="occupancyMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="occupancyLabel"
                readonly
                v-bind="attrs"
                v-on="on"
                rounded
                outlined
              ></v-text-field>
            </template>
            <v-card class="pa-5">
              <roomsAndOccupancy />
              <p class="caption error--text mb-0 mt-2">{{ occupancyNote }}</p>
              <p class="caption error--text mb-0 mt-2">{{$t('flightSearch.occupancyTerms.infantsNote')}}</p>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import autocomplete from '@/components/flights/autocomplete'
import roomsAndOccupancy from '@/components/roomsAndOccupancy'
import { mapState } from 'vuex'

export default {
  components: { autocomplete, roomsAndOccupancy },
  data () {
    return {
      returnSaudiAirport: null,
      destination: null,
      tempDates: [],
      startDateMenu: false,
      endDateMenu: false,
      occupancyMenu: false,
      meccaStayDurations: 2,
      medinaStayDurations: 1,
      classType: {
        text: 'economy', value: 'Y'
      }
    }
  },
  watch: {
    returnSaudiAirport (value) {
      this.$store.dispatch('setReturnAirport', value)
    },
    destination (value) {
      this.$store.dispatch('setDestinationAirport', value)
    },
    tempDates (val) {
      if (new Date(val[0]).getTime() < new Date(val[1]).getTime()) {
        this.$store.dispatch('updateStartDate', val[0])
        this.$store.dispatch('updateReturnDate', val[1])
      } else {
        this.$store.dispatch('updateStartDate', val[1])
        this.$store.dispatch('updateReturnDate', val[0])
      }
      if (new Date(val[1]).getTime() < new Date(this.packageReturnMinDate).getTime()) this.tempDates[1] = this.packageReturnMinDate
    },
    meccaStayDurations (value) {
      if (value >= 2) this.$store.dispatch('updateMeccaDuration', value)
    },
    medinaStayDurations (value) {
      if (value >= 0) this.$store.dispatch('updateMedinaDuration', value)
    }
  },
  computed: {
    ...mapState(['packageDetails', 'startDate', 'returnDate', 'destinationAirport', 'returnAirport', 'roomsAndoccupancy', 'occupancyNote', 'meccaNights', 'medinaNights', 'flightClass']),
    minStartDate () {
      // new Date(packageDetails.season_validation[0].end_season_ongoing_from).toISOString().substring(0, 10)
      if (new Date(this.packageDetails.season_validation[0].end_season_ongoing_from).getTime() >= new Date().getTime()) {
        return new Date(this.packageDetails.season_validation[0].end_season_ongoing_from).toISOString().substring(0, 10)
      } else {
        return new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10)
      }
    },
    maxStayDuration () {
      const diffInMs = Math.abs(new Date(this.returnDate) - new Date(this.startDate))
      return diffInMs / (1000 * 60 * 60 * 24)
    },
    destinationAirports () {
      if (this.returnAirport.Code === 'MED') {
        return [{
          Code: 'JED',
          Name: 'King Abdulaziz Int',
          City: 'Jeddah',
          countryCode: 'SA',
          Country: 'Saudi Arabia',
          Type: 'A'
        }]
      } else {
        return [
          {
            Code: 'JED',
            Name: 'King Abdulaziz Int',
            City: 'Jeddah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          },
          {
            Code: 'MED',
            Name: 'Madinah',
            City: 'Madinah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          }
        ]
      }
    },
    returnAirports () {
      if (this.destinationAirport.Code === 'MED') {
        return [{
          Code: 'JED',
          Name: 'King Abdulaziz Int',
          City: 'Jeddah',
          countryCode: 'SA',
          Country: 'Saudi Arabia',
          Type: 'A'
        }]
      } else {
        return [
          {
            Code: 'JED',
            Name: 'King Abdulaziz Int',
            City: 'Jeddah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          },
          {
            Code: 'MED',
            Name: 'Madinah',
            City: 'Madinah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          }
        ]
      }
    },
    tempStartDateText () {
      if (this.tempDates[0]) {
        // return new Date(this.tempDates[0]).toDateString()
        return this.$d(new Date(this.tempDates[0].replace(/-/g, '/')), 'short')
      } else return ''
    },
    tempEndDateText () {
      if (this.tempDates[1]) {
        // return new Date(this.tempDates[1]).toDateString()
        return this.$d(new Date(this.tempDates[1].replace(/-/g, '/')), 'short')
      } else return ''
    },
    packageReturnMinDate () {
      const minDate = this.tempDates[0] ? new Date(this.tempDates[0].replace(/-/g, '/')) : new Date()
      minDate.setDate(minDate.getDate() + 3)
      return minDate.toISOString().substr(0, 10)
    },
    packageReturnMaxDate () {
      let maxDate = this.tempDates[0] ? new Date(this.tempDates[0].replace(/-/g, '/')) : new Date()
      maxDate.setMonth(maxDate.getMonth() + 1)
      if (new Date(maxDate).getTime() > new Date(this.packageDetails.season_validation[0].end_season_ongoing).getTime()) maxDate = new Date(this.packageDetails.season_validation[0].end_season_ongoing)
      return maxDate.toISOString().substr(0, 10)
    },
    // classType () {
    //   return { text: this.translateClass('economy'), value: 'Y' }
    // },
    classes () {
      return [
        { text: 'economy', value: 'Y' },
        { text: 'premium', value: 'S' },
        { text: 'business', value: 'C' },
        { text: 'first', value: 'F' }
      ]
    },
    occupancyLabel () {
      let guestsNumber = 0
      this.roomsAndoccupancy.forEach(element => {
        guestsNumber += element.adults
        guestsNumber += element.children.length
      })
      return this.$tc('hotel.hotels.roomCount', this.roomsAndoccupancy.length) + ' - ' + this.$tc('hotel.hotels.guests', guestsNumber)
    }
  },
  methods: {
    setClass () {
      this.$store.dispatch('setPassengersClass', this.classType)
    },
    translateClass (classLabel) {
      return this.$t(`flightSearch.classes.${classLabel.toLowerCase()}`)
    },
    // changePassengersNumbers (passengerType, method) {
    //   this.$store.dispatch('changePassengersNumbers', { type: passengerType, action: method })
    //   if (this.infants > this.adults) this.$store.dispatch('changePassengersNumbers', { type: 'infants', action: 'dec' })
    //   if (this.oldChildren + this.adults > 4) this.$store.dispatch('changePassengersNumbers', { type: 'oldChildren', action: 'dec' })
    // },
    displayError (msg) {
      this.$emit('error', msg)
    }
  },
  created () {
    if (this.$store.state.originAirport) this.search = this.$store.state.originAirport.Code
    if (this.$store.state.destinationAirport) this.destination = this.$store.state.destinationAirport
    else this.destination = this.$store.state.packageDetails.packageStartCity[0]
    if (this.$store.state.returnAirport) this.returnSaudiAirport = this.$store.state.returnAirport
    else if (this.$store.state.destinationAirport.Code === 'MED') {
      this.returnSaudiAirport = {
        Code: 'JED',
        Name: 'King Abdulaziz Int',
        City: 'Jeddah',
        countryCode: 'SA',
        Country: 'Saudi Arabia',
        Type: 'A'
      }
    } else this.returnSaudiAirport = this.$store.state.packageDetails.packageStartCity[0]
    if (this.startDate) this.tempDates[0] = this.startDate
    else {
      if (new Date(this.packageDetails.season_validation[0].end_season_ongoing_from).getTime() >= new Date().getTime()) {
        this.tempDates[0] = new Date(this.packageDetails.season_validation[0].end_season_ongoing_from).toISOString().substring(0, 10)
      } else {
        this.tempDates[0] = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10)
      }
      this.tempDates[1] = new Date(this.packageDetails.season_validation[0].end_season_return_from).toISOString().substring(0, 10)
      this.$store.dispatch('updateStartDate', this.tempDates[0])
      this.$store.dispatch('updateReturnDate', this.tempDates[1])
    }
    if (this.returnDate) {
      this.tempDates[1] = this.returnDate
    }
    if (this.meccaNights) this.meccaStayDurations = this.meccaNights
    if (this.medinaNights) this.medinaStayDurations = this.medinaNights
    if (this.flightClass) {
      this.classType = this.flightClass
    }
  }
}
</script>

<style>
.v-date-picker-table .v-btn {
  font-size: 16px !important;
  font-weight: 700;
  margin-bottom: 0.25px;
}
.v-date-picker-header__value {
  font-size: 18px;
}
</style>
