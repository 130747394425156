// /airport/autocomplete?term=${term}
import Vue from 'vue'
import { baseAPIURL } from '../APILinks'

const timeout = 120000
export default {
  // get airportd
  getAirports (query) {
    return Vue.axios.get(baseAPIURL + 'flight/autocomplete',
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        params: query
      })
  },

  // get cheapest flights
  getFlights (query) {
    return Vue.axios.get(baseAPIURL + 'flight/lowestPrice',
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        params: query,
        data: null
      })
  },

  // get flights
  getAllFlights (query) {
    return Vue.axios.get(baseAPIURL + 'flight/search?' + query,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      })
  },

  // get flight
  getFlight (query) {
    return Vue.axios(baseAPIURL + 'flight?' + query,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      })
  },

  // create pnr
  createPNR (body) {
    return Vue.axios.post(baseAPIURL + 'pnr/create',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  }
}
