<template>
<!--  :dir="$i18n.locale === 'en' ? 'ltr' : 'rtl'" -->
  <v-app>
    <Navbar />

    <stepper />

    <v-main>
      <router-view/>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import stepper from '@/components/stepper'
import Footer from '@/components/Footer'
import { mapState } from 'vuex'

export default {
  components: {
    Navbar,
    Footer,
    stepper
  },
  name: 'App',
  computed: { ...mapState(['currencies']) }
}
</script>

<style lang="scss">
.v-application {
  $body-font-family: 'Raleway', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
}
p {
  font-size: 16px;
}
.headline-text {
  position: relative;
}
.headline-text::after {
  content: '';
  width: 20%;
  height: 5px;
  background-color: #1a3057;
  position: absolute;
  top: 100%;
  left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
