<template>
    <div>
        <v-stepper>
            <v-stepper-header>
                <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-magnify"
                >
                    Search
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                step="2"
                :complete="$route.name === 'flightResults' || $route.name.includes('Hotels') || $route.name === 'packageBook'"
                 complete-icon="mdi-airplane"
                >
                    Flight
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                v-if="($store.state.medinaNights > 0 &&  $store.getters.startCity !== 'JED') || $store.getters.startCity === 'JED'"
                step="3"
                :complete="$route.name.includes('Hotels') || $route.name === 'packageBook'"
                 complete-icon="mdi-bed"
                >
                    {{ $store.getters.startCity === 'JED' ? 'Mecca Hotel' : 'Medina Hotel' }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                v-if="($store.state.medinaNights > 0 &&  $store.getters.startCity !== 'JED') || $store.getters.startCity === 'JED'"
                step="4"
                :complete="$route.name === 'medinaHotels' || $route.name === 'packageBook'"
                 complete-icon="mdi-bed"
                >
                {{ $store.getters.startCity !== 'JED' ? 'Mecca Hotel' : 'Medina Hotel' }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                step="5"
                :complete="$route.name === 'packageBook'"
                 complete-icon="mdi-ticket"
                >
                    Booking
                </v-stepper-step>
            </v-stepper-header>
        </v-stepper>
    </div>
</template>

<script>
export default {

}
</script>
