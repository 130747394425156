import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currency: {
      code: 'USD',
      countryName: 'UNITED STATES',
      rate: 1,
      name: 'DOLLAR'
    },
    currencies: null,
    packages: [],
    packageDetails: null,
    adults: 2,
    children: 0,
    oldChildren: 0,
    infants: 0,
    meccaNights: 2,
    medinaNights: 1,
    roomsAndoccupancy: [
      {
        adults: 2,
        children: []
      }
    ],
    meccaHotel: null,
    medinaHotel: null,
    meccaRoomsParams: null,
    medinaRoomsParams: null,
    occupancyNote: '',
    startDate: null,
    returnDate: null,
    originAirport: null,
    destinationAirport: {
      Code: 'JED',
      Name: 'King Abdulaziz Int',
      City: 'Jeddah',
      countryCode: 'SA',
      Country: 'Saudi Arabia',
      Type: 'A'
    },
    returnAirport: {
      Code: 'JED',
      Name: 'King Abdulaziz Int',
      City: 'Jeddah',
      countryCode: 'SA',
      Country: 'Saudi Arabia',
      Type: 'A'
    },
    homeAirport: null,
    flightClass: { text: 'economy', value: 'Y' },
    flight: null,
    writtenAddress: '',
    zipCode: '',
    writtenCity: 'US',
    writtenState: '',
    writtenCountry: '',
    packagePrice: 0,
    flightResults: null,
    makkahHotel: null,
    madinaHotel: null,
    cities: [],
    stayFromDate: null,
    stayToDate: null
  },
  getters: {
    packageDetails (state) {
      const details = state.packageDetails
      details.cities.forEach(city => {
        if (city.code === 'MED') city.cityHotel = state.madinaHotel
        else city.cityHotel = state.makkahHotel
      })
      return details
    },
    startCity (state) {
      return state.destinationAirport.Code
    }
  },
  mutations: {
    setCurrency (state, payload) {
      state.currency = payload
    },
    setCurrencies (state, payload) {
      state.currencies = payload
    },
    setPackages (state, payload) {
      state.packages = payload
    },
    setPackage (state, payload) {
      state.packageDetails = payload
    },
    setCities (state, payload) {
      state.packageDetails.cities = payload
    },
    setPackageNights (state, payload) {
      state.packageDetails.nights = payload
    },

    // increase room
    increaseRoom (state) {
      state.roomsAndoccupancy.push({
        adults: 2,
        children: []
      })
    },

    // decrease room
    decreaseRoom (state) {
      state.roomsAndoccupancy.pop()
    },

    // update cities durations
    updateMeccaDuration (state, nights) {
      state.meccaNights = nights
    },
    updateMedinaDuration (state, nights) {
      state.medinaNights = nights
    },

    // hotel mutations
    setMakkahHotel (state, payload) {
      state.meccaHotel = payload
    },
    setMadinaHotel (state, payload) {
      state.medinaHotel = payload
    },

    // hotel rooms
    setMeccaRoomsParams (state, payload) {
      state.meccaRoomsParams = payload
    },
    setMedinaRoomsParams (state, payload) {
      state.medinaRoomsParams = payload
    },

    increaseHotelNights (state, payload) {
      state.packageDetails.cities.map(item => item === payload ? item.cityNightNumber++ : null)
    },
    decreaseHotelNights (state, payload) {
      state.packageDetails.cities.map(item => item === payload ? item.cityNightNumber-- : null)
    },
    setStayDates (state, payload) {
      state.stayFromDate = payload.from
      state.stayToDate = payload.to
    },

    // date mutation
    updateStartDate (state, payload) {
      state.startDate = payload
    },
    updateReturnDate (state, payload) {
      state.returnDate = payload
    },

    // passenger mutations
    increaseAdultsNumbers (state, roomIndex) {
      state.roomsAndoccupancy[roomIndex].adults++
    },
    decreaseAdultsNumbers (state, roomIndex) {
      state.roomsAndoccupancy[roomIndex].adults--
    },
    increaseChildrenNumbers (state, { roomIndex, child }) {
      state.roomsAndoccupancy[roomIndex].children.push(child)
    },
    decreaseChildrenNumbers (state, roomIndex) {
      state.roomsAndoccupancy[roomIndex].children.pop()
    },

    // occupancy note
    setOccupancyNote (state, note) {
      state.occupancyNote = note
      setTimeout(() => {
        state.occupancyNote = ''
      }, 3000)
    },

    // children ages
    increaseChildAge (state, { roomIndex, childIndex }) {
      state.roomsAndoccupancy[roomIndex].children[childIndex].age += 1
    },
    decreaseChildAge (state, { roomIndex, childIndex }) {
      state.roomsAndoccupancy[roomIndex].children[childIndex].age -= 1
    },

    increaseOldChildrenNumbers (state) {
      state.oldChildren++
    },
    decreaseOldChildrenNumbers (state) {
      state.oldChildren--
    },
    increaseInfantsNumbers (state) {
      state.infants++
    },
    decreaseInfantsNumbers (state) {
      state.infants--
    },

    // flight airport mutation
    setOriginAirport (state, payload) {
      state.originAirport = payload
    },

    setDestinationAirport (state, payload) {
      state.destinationAirport = payload
    },

    setReturnAirport (state, payload) {
      state.returnAirport = payload
    },

    setHomeAirport (state, payload) {
      state.homeAirport = payload
    },

    // flight class mutations
    setPassengersClass (state, payload) {
      state.flightClass = payload
    },

    // flight mutations
    setFlight (state, payload) {
      state.flight = payload
    },
    removeFlight (state) {
      state.flight = null
    },
    setFlightResults (state, payload) {
      state.flightResults = payload
    },
    removeFlightResults (state) {
      state.flightResults = null
    },

    // address info mutations
    setWrittenAddress (state, payload) {
      state.writtenAddress = payload
    },
    setZipCode (state, payload) {
      state.zipCode = payload
    },
    setWrittenCity (state, payload) {
      state.writtenCity = payload
    },
    setWrittenState (state, payload) {
      state.writtenState = payload
    },
    setWrittenCountry (state, payload) {
      state.writtenCountry = payload
    },

    // package price
    setPackagePrice (state, payload) {
      state.packagePrice = payload
    }
  },

  // Actions
  actions: {
    setCurrency ({ commit }, payload) {
      commit('setCurrency', payload)
    },
    setCurrencies ({ commit }, payload) {
      commit('setCurrencies', payload)
    },
    setPackages ({ commit }, payload) {
      commit('setPackages', payload)
    },
    setPackage ({ commit }, payload) {
      commit('setPackage', payload)
    },
    setCities ({ commit }, payload) {
      commit('setCities', payload)
    },
    setPackageNights ({ commit }, payload) {
      commit('setPackageNights', payload)
    },

    // increase room
    increaseRoom (context) {
      if (context.state.roomsAndoccupancy.length < 6) context.commit('increaseRoom')
    },

    // decrease room
    decreaseRoom (context) {
      if (context.state.roomsAndoccupancy.length > 1) context.commit('decreaseRoom')
    },

    // increase adults number
    increaseAdultsNumbers (context, roomIndex) {
      if (context.state.roomsAndoccupancy[roomIndex].adults + context.state.roomsAndoccupancy[roomIndex].children.filter(child => child.age >= 6).length < 4) {
        context.commit('increaseAdultsNumbers', roomIndex)
      } else {
        context.commit('setOccupancyNote', 'Number of adults and children above 6 years must not exceed 4 persons in one room!')
      }
    },

    // decrease adults number
    decreaseAdultsNumbers (context, roomIndex) {
      const room = context.state.roomsAndoccupancy[roomIndex]
      if (room.adults > 1 && room.adults > room.children.filter(child => child.age < 2).length) {
        context.commit('decreaseAdultsNumbers', roomIndex)
      } else {
        context.commit('setOccupancyNote', 'Number of adults must more than children under 2 years and above one person!')
      }
    },

    // increase adults number
    increaseChildrenNumbers (context, roomIndex) {
      const room = context.state.roomsAndoccupancy[roomIndex]
      if (room.adults > room.children.filter(child => child.age < 2).length) {
        const child = { age: 1 }
        context.commit('increaseChildrenNumbers', { roomIndex, child })
      } else if (room.children.filter(child => child.age >= 2 && child.age < 6).length < 4) {
        const child = { age: 2 }
        context.commit('increaseChildrenNumbers', { roomIndex, child })
      } else if (room.adults + room.children.filter(child => child.age >= 6).length <= 4) {
        const child = { age: 6 }
        context.commit('increaseChildrenNumbers', { roomIndex, child })
      } else {
        context.commit('setOccupancyNote', 'You have excedded the number of children!')
      }
    },

    // decrease adults number
    decreaseChildrenNumbers (context, roomIndex) {
      if (context.state.roomsAndoccupancy[roomIndex].children.length > 0) {
        context.commit('decreaseChildrenNumbers', roomIndex)
        // context.state.roomsAndoccupancy[roomIndex].childrenAges.pop()
      }
    },

    // update children ages
    increaseChildAge (context, { roomIndex, childIndex }) {
      if (context.state.roomsAndoccupancy[roomIndex].children[childIndex].age < 12) context.commit('increaseChildAge', { roomIndex, childIndex })
    },
    decreaseChildAge (context, { roomIndex, childIndex }) {
      if (context.state.roomsAndoccupancy[roomIndex].children[childIndex].age > 1) context.commit('decreaseChildAge', { roomIndex, childIndex })
    },

    // update cities durations
    updateMeccaDuration ({ commit }, nights) {
      commit('updateMeccaDuration', nights)
    },
    updateMedinaDuration ({ commit }, nights) {
      commit('updateMedinaDuration', nights)
    },

    // hotel actions
    changeHotelsNights  (context, payload) {
      const city = context.state.packageDetails.cities.find(city => city.cityID === payload.id)
      payload.action === 'inc' ? context.commit('increaseHotelNights', city) : context.commit('decreaseHotelNights', city)
    },
    setMeccahHotel ({ commit }, payload) {
      commit('setMakkahHotel', payload)
    },
    setMedinaHotel ({ commit }, payload) {
      commit('setMadinaHotel', payload)
    },
    setStayDates ({ commit }, payload) {
      commit('setStayDates', payload)
    },
    setHotel ({ commit }, payload) {
      payload.city === 'mecca' ? commit('setMakkahHotel', payload.hotels) : commit('setMadinaHotel', payload.hotels)
    },

    // hotel rooms
    setMeccaRoomsParams ({ commit }, payload) {
      commit('setMeccaRoomsParams', payload)
    },
    setMedinaRoomsParams ({ commit }, payload) {
      commit('setMedinaRoomsParams', payload)
    },

    // passengers actions
    changePassengersNumbers ({ commit }, payload) {
      if (payload.type === 'adults') {
        payload.action === 'inc' ? commit('increaseAdultsNumbers') : commit('decreaseAdultsNumbers')
      } else if (payload.type === 'children') {
        payload.action === 'inc' ? commit('increaseChildrenNumbers') : commit('decreaseChildrenNumbers')
      } else if (payload.type === 'oldChildren') {
        payload.action === 'inc' ? commit('increaseOldChildrenNumbers') : commit('decreaseOldChildrenNumbers')
      } else {
        payload.action === 'inc' ? commit('increaseInfantsNumbers') : commit('decreaseInfantsNumbers')
      }
    },

    // date actions
    updateStartDate ({ commit }, payload) {
      commit('updateStartDate', payload)
    },
    updateReturnDate ({ commit }, payload) {
      commit('updateReturnDate', payload)
    },

    // flight airport
    setOriginAirport ({ commit }, payload) {
      commit('setOriginAirport', payload)
    },

    setDestinationAirport ({ commit }, payload) {
      commit('setDestinationAirport', payload)
    },

    setReturnAirport ({ commit }, payload) {
      commit('setReturnAirport', payload)
    },

    setHomeAirport ({ commit }, payload) {
      commit('setHomeAirport', payload)
    },

    // flight actions
    setPassengersClass ({ commit }, payload) {
      commit('setPassengersClass', payload)
    },
    setFlight ({ commit }, payload) {
      commit('setFlight', payload)
    },
    removeFlight ({ commit }) {
      commit('removeFlight')
    },
    setFlightResults ({ commit }, payload) {
      commit('setFlightResults', payload)
    },
    removeFlightResults ({ commit }) {
      commit('removeFlightResults')
    },

    // address info
    setWrittenAddress ({ commit }, payload) {
      commit('setWrittenAddress', payload)
    },
    setZipCode ({ commit }, payload) {
      commit('setZipCode', payload)
    },
    setWrittenCity (context, payload) {
      context.commit('setWrittenCity', payload)
    },
    setWrittenState (context, payload) {
      context.commit('setWrittenState', payload)
    },
    setWrittenCountry (context, payload) {
      context.commit('setWrittenCountry', payload)
    },

    // package price
    setPackagePrice ({ commit }, payload) {
      commit('setPackagePrice', payload)
    }
  },
  modules: {
  }
})
