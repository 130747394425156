<template>
  <div class="bg">
    <packageDetails class="package-details" />
  </div>
</template>

<script>
import packageDetails from '@/views/packages/packageDetails'

export default {
  name: 'Home',

  components: {
    packageDetails
  }
}
</script>

<style>
.bg {
  background: url('../assets/bg.jpeg') no-repeat;
  background-size: 100% 40vh;
  overflow-y: visible;
  min-height: 25vh;
}
.package-details {
  transform: translateY(5vh);
}
@media screen and (max-width: 960px) {
  .bg {
    background-size: 100% 25%;
  }
}
</style>
